<template>
  <div class="card tw-pb-20">
    <h6 class="tw-text-xl tw-font-bold tw-mb-8">
      {{ state === 'create' ? 'Tambah Admin' : 'Edit Admin' }}
    </h6>

    <form class="p-fluid" @submit.prevent="doSubmit()">
      <div class="field">
        <label>Email</label>
        <InputText type="email" v-model="form.email" required />
      </div>
      <div class="field">
        <label>No. HP</label>
        <InputText type="number" v-model="form.phone_number" required />
      </div>
      <div class="field" v-if="state === 'create'">
        <label>Password</label>
        <InputText type="password" v-model="form.password" required />
      </div>

      <div class="field col-12 md:col-3" v-if="isAdminPriti()">
        <label>Role</label>
        <Dropdown v-model="selected_role"
          :options="roles"
          optionLabel="name"
          placeholder="Polih Role"
          @change="setRole()">
        </Dropdown>
      </div>

      <div class="field col-12 md:col-3" v-if="isAdminPriti() && selected_role && selected_role.code === 'admin_salon'">
        <label>Salon</label>
        <Dropdown v-model="selected_salon"
          :options="salons"
          optionLabel="name"
          placeholder="Pilih Salon"
          @change="setSalon()">
        </Dropdown>
      </div>

      <div class="field col-12 md:col-3" v-if="state === 'edit'">
        <label>Status</label>
        <Dropdown v-model="selected_status"
          :options="admin_status"
          optionLabel="name"
          placeholder="Pilih Status"
          @change="setStatus()">
        </Dropdown>
      </div>

      <div class="p-fluid tw-mt-12 tw-mb-4">
        <Button type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script>
  import { useAuthRole } from '@/composables/auth';

  export default {
    setup() {
      const {isAdminPriti, getUser } = useAuthRole()
      return { isAdminPriti, getUser }
    },
    props: ['id'],
    data() {
      return {
        state: 'create',
        form: {
          email: '',
          phone_number: '',
          password: '',
          role: '',
          status: 0,
        },
        user_attribute: {},
        salons: [],
        roles: [
          {name: 'Admin Priti', code: 'admin_priti'},
          {name: 'Admin Salon', code: 'admin_salon'},
        ],
        admin_status: [
          { id: 0, name: 'InActive' },
          { id: 1, name: 'Active' },
          { id: 2, name: 'Suspend' },
        ],
        selected_salon: null,
        selected_role: null,
        selected_status: null,
      }
    },
    async mounted () {
      this.state = this.id ? 'edit' : 'create'
      await this.getSalons()
      await this.getData()
    },
    methods: {
      async getData() {
        if (!this.id) return

        await this.axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${this.id}/admin`).then(response => {
          if (response.status === 200) {
            let data = response.data.data
            this.form.email = data.email
            this.form.phone_number = data.phone_number
            this.form.status = data.status
            this.selected_status = this.admin_status.find((e) => e.id === data.status)
            this.setStatus()

            let user_attributes = response.data.data.user_attributes
            user_attributes.forEach(attribute => {
              this.user_attribute[attribute.name] = attribute.value
            });

            this.selected_role = this.roles.find(e => e.code === this.user_attribute['role'])
            this.setRole()

            if (this.user_attribute['role'] === 'admin_salon') {
              this.selected_salon = this.salons.find(e => e.id === parseInt(this.user_attribute['salon_id']))
              this.setSalon()
            }
          }
        })
      },
      async getSalons() {
        await this.axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/`).then(response => {
          this.salons = response.data.data.salons
        })
      },
      setRole() {
        this.form.role = this.selected_role.code
      },
      setStatus() {
        this.form.status = this.selected_status.id
      },
      setSalon() {
        this.form.salon_id = this.selected_salon.id
      },
      doSubmit() {
        let API_URL = `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user`
        let endpoint = this.state === 'create' ? 'admin' : `${this.id}/admin`
        let methods = this.state === 'create' ? 'post' : 'put'

        if (!this.isAdminPriti()) {
          this.form.role = 'admin_salon'
        }

        this.axios[methods](`${API_URL}/${endpoint}`, this.form).then(response => {
          if (response.status === 200) {
            this.$router.push({name: 'admins'})
          } else {
            this.$toast.add({
              severity: 'error',
              closable: true,
              summary: 'Error',
              detail: response.data.message
            })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>